<template>
  <div>
    <modal name="user-post-interactive-modal"
           :click-to-close="false"
           width="500px"
           height="auto"
           class="create-group-modal modal-custom user-post-interactive-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Tương tác</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('user-post-interactive-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content class="post-layout">
        <div style="overflow: auto;height: 500px;" class="post-footer">
          <vue-loadmore
              :on-loadmore="onLoadUserAction"
              :finished="finished"
              loading-text="Đang tải"
              finished-text="Hết">
            <md-list-item v-for="item in userAction" :key="item.id">
              <md-avatar>
                <img class="avatar" :src="item.avatar?item.avatar:'/img/profile/avatar.png'">
              </md-avatar>
              <div class="md-list-item-text">
                <span class="text-400-semibold ">{{ item.fullname}}</span>
              </div>
              <template v-if="type == 'like'">
                <template v-if="item.like_type == 1">
                  <div class="reaction-icon reaction-like"></div>
                </template>
                <template v-if="item.like_type == 2">
                  <div class="reaction-icon reaction-love"></div>
                </template>
                <template v-if="item.like_type == 3">
                  <div class="reaction-icon reaction-care"></div>
                </template>
                <template v-if="item.like_type == 4">
                  <div class="reaction-icon reaction-haha"></div>
                </template>
                <template v-if="item.like_type == 5">
                  <div class="reaction-icon reaction-wow"></div>
                </template>
                <template v-if="item.like_type == 6">
                  <div class="reaction-icon reaction-sad"></div>
                </template>
                <template v-if="item.like_type == 7">
                  <div class="reaction-icon reaction-angry"></div>
                </template>
              </template>
            </md-list-item>

          </vue-loadmore>
        </div>
      </md-card-content>
    </modal>
  </div>
</template>

<script>

import PostService from "../../../store/services/PostService";

export default {
  name: 'user-post-interactive-modal',
  data() {
    return {
      postId: null,
      type: 'like',
      userAction: [],
      params: {
        page: 1,
        per_page: 10,
      },
      lastPage: 1,
      finished: false
    }
  },
  methods: {
    beforeOpened(event) {
      this.postId = event.params.postId;
      this.type = event.params.type;
      this.params.page = 1;
      this.params.per_page = 10;
      this.lastPage = 1;
      this.finished = false;

      if(this.type == 'like'){
        this.getUserLikes();
      }else {
        this.getUserRead();
      }
      // this.$store.commit("updateModalIsOpen", true);
    },

    beforeClosed(event){
      // this.$store.commit("updateModalIsOpen", false);
    },

    onLoadUserAction(done) {
      if (this.params.page < this.lastPage) {
        this.params.page++
        if(this.type == 'like'){
          this.getUserLikes(true);
        }else {
          this.getUserRead(true);
        }
      } else {
        this.finished = true;
      }
      done();
    },
    getUserLikes(loadMore = false) {
      PostService.getUserLikePost(this.postId, this.params).then(res => {
        if (res) {
          this.isLoad = false;
          this.lastPage = res.meta.last_page
          if (loadMore) {
            this.userAction = this.userAction.concat(res.data)
          } else {
            this.userAction = res.data;
          }
        }
      });
    },
    getUserRead(loadMore = false) {
      PostService.getUserReadPost(this.postId, this.params).then(res => {
        if (res) {
          this.isLoad = false;
          this.lastPage = res.meta.last_page
          if (loadMore) {
            this.userAction = this.userAction.concat(res.data)
          } else {
            this.userAction = res.data;
          }
        }
      });
    },
  }

}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    max-width: 515px !important;
  }
}
.user-post-interactive-modal{
  .user-info-wrapper {
    .md-avatar {
      width: 100px !important;
      height: 100px !important;
    }
    .info-text {
      width: 70%;
    }
    .info-image {
      width: 30%;
      img {
        border-radius: 50%;
      }
    }
  }

  .reaction-icon{
    width: 30px;
    height: 30px;
  }

  .follow-button {
    width: 195px;
    height: 35px;
    background-color: #EDFAD0;
    border: none;
    border-radius: 4px;
  }
}

</style>
