<template>
  <div>
    <modal name="create-chat-bot-modal"
           :click-to-close="false"
           width="620px"
           height="auto"
           class="create-group-modal modal-custom create-chat-bot-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <template>
        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="neutral_1 heading-500 text-header-modal">Soạn tin nhắn</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('create-chat-bot-modal')">close</span>
          </md-dialog-title>
        </md-card>
        <md-card-content>
          <div class="content-report-modal">
            <!-- message type -->
            <div class="fileUploadContainer">
              <div class="title-report">
                <span>Nội dung</span>
              </div>
              <div class="uploadfile">
                <img class="footer-icon upload-image" title="Tải lên video" :class="activeTypeUpload === 'video' ? 'disable-file' : ''" src="/img/chat/video.svg" @click="openUpload('video')">
                <img class="footer-icon upload-image" title="Tải lên hình ảnh" :class="activeTypeUpload === 'image' ? 'disable-file' : ''" src="/img/chat/image.svg" @click="openUpload('image')">
                <img class="footer-icon upload-file" title="Tải lên file"  :class="activeTypeUpload === 'file' ? 'disable-file' : ''" src="/img/chat/attachment.svg" @click="openUpload('file')">
                <input ref="imageUpload" @change="onChangeUpload($event, 'image')" type="file" id="image" multiple
                       accept="image/*" hidden>
                <input ref="fileUpload" @change="onChangeUpload($event, 'file')" type="file" id="file" multiple
                       hidden>
                <input ref="videoUpload" @change="onChangeUpload($event, 'video')" type="file" id="video" multiple
                       accept="video/*" hidden>
              </div>
            </div>
            <div class="file-upload border-top aza-scroll" style="overflow-x: auto;overflow-y: hidden;" v-if="medias.length > 0">
              <div class="file-upload__container">
                <!-- <vue-element-loading :active="loadingFile" spinner="bar-fade-scale"/> -->
                <div v-for="(file, index) in medias" :key="index" class="upload-file-chat">
                  <vue-element-loading :active="file.loading == 'loading'" spinner="bar-fade-scale"/>
                  <template v-if="file.type === 'video'">
                    <div class="photo-upload-item">
                      <template>
                        <div class="p-relative">
                          <video class="file-img" controls="false" style="margin: auto">
                            <source :src="file.path">
                            Sorry, your browser doesn't support embedded videos.
                          </video>
                          <div class="w_100 h_100 p-absolute " style="top: 0px; left: 0px"></div>
                        </div>
                      </template>
                      <div class="photo-delete__action" @click="removeFile(index)">
                        <md-icon>close</md-icon>
                      </div>
                    </div>
                  </template>
                  <template v-if="file.type === 'image'">
                    <div class="photo-upload-item">
                      <template>
                        <img class="file-img" :src="file.path">
                      </template>
                      <div class="photo-delete__action" @click="removeFile(index)">
                        <md-icon>close</md-icon>
                      </div>
                    </div>
                  </template>
                  <template v-if="file.type === 'file'">
                    <div class="file-upload-item mx-2">
                      <div class="item-container">
                        <div class="url-colection-thumb mr-5px"><img src="/img/chat/Type-Other.svg" alt="thumb"></div>
                        <div class="file-content">
                          <div class="heading-400 text-1-line wb-break-all">{{ file.name }}</div>
                          <div class="text-300-default neutral_2"><span>{{
                              file.extension
                            }} - {{ bytesToSize(file.file_size) }}</span></div>
                        </div>
                      </div>
                      <div class="photo-delete__action" @click="removeFile(index)">
                        <md-icon>close</md-icon>
                      </div>
                    </div>
                  </template>
                  <template v-if="file.loading == 'error'">
                    <div class="error-upload">
                      <span>Lỗi</span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="content-ckeditor mt-15px">
              <textarea v-model="html_content"></textarea>
<!--              <ckeditor v-model="html_content" :config="editorConfig"></ckeditor>-->
            </div>
          </div>
        </md-card-content>
        <md-dialog-actions class="p-15px w_100">
          <button type="submit" class="btn-active md-button" @click="sendMessageReport">Tạo tin nhắn
          </button>
          <button type="submit" class="md-button" @click="closeModal">Hủy
          </button>
        </md-dialog-actions>
      </template>
    </modal>
  </div>
</template>

<script>

import ChatService from "../../../../store/services/ChatService";
import PostService from "../../../../store/services/PostService";
import {helpers} from "../../../../helper/helpers";
import {MESSAGE_CHAT_TYPE} from "../../../../const/const";

export default {
  name: 'create-chat-bot-modal',
  components: {},
  computed: {},
  mounted() {

  },

  data() {
    return {
      medias: [],
      activeTypeUpload: '',
      html_content: '',
      editorConfig: {
        toolbar: [
          ['Format', 'Font', 'FontSize', 'Bold','Italic','Strike'],
          ['NumberedList','BulletedList'],
        ]
      },
      message_type: null,
      param_message: {
        type: MESSAGE_CHAT_TYPE.IMAGE,
        file_ids: [],
        message: '',
        group_id: null
      },
      chatGroupId: null,
    };
  },
  watch: {
  },
  methods: {
    beforeOpened(event) {
      this.resetParams();
      this.param_message.group_id = event.params.chatGroupId
    },

    beforeClosed(event) {

    },
    openUpload(type) {
      if (this.activeTypeUpload == '' || type === this.activeTypeUpload) {
        switch (type) {
          case 'file':
            this.$refs.fileUpload.click();
            break;
          case 'image':
            this.$refs.imageUpload.click();
            break;
          case 'video':
            this.$refs.videoUpload.click();
            break;
        }
        // this.typingMessage = true;
      }
    },

    async onChangeUpload(e, type, fileOnPaste = false) {
      const formFile = new FormData();
      this.activeTypeUpload = type;
      let selectedFiles = fileOnPaste ? fileOnPaste : e.target.files;
      let vm = this;

      //format type file
      let types = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        let typeFile = selectedFiles[i].type.split('/')[0];
        if(!types.includes(typeFile) || types.length == 0){
          types.push(['video', 'image'].includes(typeFile) ? typeFile : 'file');
        }
      }
      let type_upload = types.find((item) => item !== type) ?? type;

      for (let i = 0; i < selectedFiles.length; i++) {
        let typeFile = selectedFiles[i].type;
        let item = {
          name: selectedFiles[i].name,
          file_size: selectedFiles[i].size,
          extension: selectedFiles[i].name.split('.').pop(),
          url: null,
          path: null,
          type: type_upload,
          loading: 'loading'
        }

        switch (type_upload) {
          case 'image':
            this.param_message.type = MESSAGE_CHAT_TYPE.IMAGE
            break;
          case 'video':
            this.param_message.type = MESSAGE_CHAT_TYPE.VIDEO
            break;
          case 'file':
            this.param_message.type = MESSAGE_CHAT_TYPE.FILE
            break;
        }

        let reader = new FileReader();
        reader.onload = (e) => {
          let src = reader.result;
          item.path = src
          item.thumbnail = {
            "small_thumb": src,
            "medium_thumb": src
          }
          vm.medias.push(item);
        };

        reader.readAsDataURL(selectedFiles[i]);

        formFile.append("files[]", selectedFiles[i]);

        await PostService.uploadFile(formFile).then(res => {
          if (res.status) {
            item.path = res.data[0].url;
            item.url = res.data[0].url;
            item.loading = false;
            item.thumbnail = res.data[0].thumbnail;
            let keyIndex = vm.medias.length > 1 ? keyIndex + i : i;
            vm.medias[keyIndex] = item;
            this.param_message.file_ids.push(res.data[0]['file_id']);
          } else {
            // vm.medias.splice(i, 1);
            item.loading = 'error';
          }
        });

        formFile.delete("files[]");
      }
      // this.form_input.uploading = false;
    },

    removeFile(index) {
      this.medias.splice(index, 1);
    },

    bytesToSize(size) {
      return helpers.bytesToSize(size)
    },

    closeModal(){
      this.resetParams();
      this.$modal.hide('create-chat-bot-modal');
    },

    sendMessageReport(){
      this.param_message.message = this.html_content;
      ChatService.createMessageChat(this.param_message).then(res => {
        if (res.status) {
          window.location.reload();
        }
      });
      this.resetParams();
    },

    resetParams(){
      this.medias = [];
      this.html_content = '';

      //format message
      this.param_message = {
        type: MESSAGE_CHAT_TYPE.TEXT,
        file_ids: [],
        message: ''
      };
    }
  }

}
</script>

<style lang="scss">
.md-menu-content{
  z-index: 99999;
}
.not-found-data{
  font-size: 13px;
}
.create-chat-bot-modal{
  textarea{
    width: 100%;
    height: 300px;
    border: 1px solid #dbdbdb;
    resize: none;
    padding: 10px;
  }
  .flex-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  padding: 15px;
  .message-type{
    margin: 0 0 10px;
    .types{
      position: relative;
      .selectd-type-button{
        &:hover{
          cursor: pointer;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 10px;
        gap: 8px;
        background: #376BFB;
        border: 1px solid #D8DAE5;
        border-radius: 4px;
        color: #F9FAFC;
        width: max-content;
        font-size: 14px;
        .md-icon{
          color: #fff;
        }
      }
      .dropdown-options {
        position: absolute;
        width: 150px;
        right: 0;
        background: #fff;
        border: 1px solid #e9e9e9;
        box-shadow: 0px 8px 16px #2f31361a;
        border-radius: 5px;
        .option {
          &:hover{
            background: #dfdcdc;
            cursor: pointer;
          }
          padding: 5px 10px;
        }
      }
      .active-normal{
        background: #52576F;
      }
      .active-report{
        background: #376BFB;
      }
      .active-bonus{
        background: #FF7B00;
      }
      .active-discipline{
        background: #D14343;
      }
      .active-kl{
        background: #5CA222;
      }
    }
  }
  .tag-list {
    border: 1px solid #D8DAE5;
    padding: 6px 10px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 5px;
    align-items: center;
    .tag {
      &:hover{
        cursor: pointer;
        box-shadow: 0px 8px 16px #2f31361a
      }
      background: #DFF9FF;
      border-radius: 8px;
      padding: 4px 6px;
      text-align: center;
      min-width: max-content;
      max-width: 100%;
    }
    .add-tag {
      background: #EDEFF5;
      border-radius: 8px;
      padding: 4px 6px;
      min-width: max-content;
      max-width: 100%;
      &:hover{
        cursor: pointer;
        box-shadow: 0px 8px 16px #2f31361a
      }
    }
  }
  .fileUploadContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    .uploadfile img {
      padding: 5px;
      &:hover{
        background: #c2c0be52;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  .file-upload__container {
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    padding: 15px 0;
    width: auto;
    .upload-file-chat {
      position: relative;
    }
    .file-upload-chat{
      .error-upload {
        width: 100% !important;
        left: 50% !important;
      }
    }
    .error-upload {
      position: absolute;
      top: 50%;
      left: 40%;
      transform: translate(-50%, -50%);
      color: red;
      background-color: #e3dedede;
      width: 60px;
      height: 60px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 19px;
    }
    .photo-upload-item {
      background-color: #ededed;
      border: 1px solid #e8e7e7;
      border-radius: 3px;
      height: 60px;
      width: 60px;
      min-height: 60px;
      min-width: 60px;
      position: relative;
      text-align: center;
      margin-right: 15px;

      .file-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .file-upload-item {
      background-color: #ededed;
      border-radius: 10px;
      display: inline-block;
      position: relative;
      width: 220px;
      margin-right: 15px;

      .item-container {
        align-items: center;
        display: flex;
        padding: 6px;

        .url-colection-thumb {
          width: 30px;
        }

        .file-content {
          width: 166px;
        }

      }
    }

    .photo-delete__action {
      top: -6px;
      right: -6px;
      position: absolute;
      cursor: pointer;
      border-radius: 20px;
      background: #978ded;
      z-index: 10;
      i {
        font-size: 16px !important;
        color: white;
      }
    }
  }
  .tag-container-modal{
    .container-item, .datetime {
      display: flex;
      grid-gap: 10px;
      .datetime{
        span {
          &:hover{
            cursor: pointer;
          }
          border: 1px solid #D8DAE5;
          border-radius: 4px;
          padding: 2px 6px;
          font-size: 13px;
        }
      }
    }
    .tag-datetime{
      .date-selected {
        border: 1px solid #D8DAE5;
        border-radius: 4px;
        background: #F4F4F4;
        margin: 6px 0 15px;
        padding: 5px 10px;
      }
      .vdatetime{
        position: absolute;
        .hidden-datetime{
          opacity: 0;
          width: 0px;
          height: 0px;
        }
      }
    }
    .location-tag{
      .location-input {
        border: 1px solid #D8DAE5;
        border-radius: 4px;
        width: 100%;
        min-height: 30px;
        padding: 6px 8px;
        color: #101840;
        font-weight: 400;
      }
    }
    .other-tag{
      .md-chips {
        border: 1px solid #D8DAE5;
        border-radius: 4px;
        padding: 0 5px !important;
        margin: 0;
        input{
          padding: 6px;
        }
        .md-ripple{
          background: #FFE4CB;
          color: #101840;
          border-radius: 3px;
        }
      }
    }
    .tag-team{
      position: relative;
      &::after{
        content: none;
      }
      .seach-branch-team{
        position: absolute;
        width: 250px;
        height: auto;
        background: #fff;
        top: 15px;
        border: 1px solid #D8DAE5;
        box-shadow: 5px 8px 44px #2f31361a
      }
      .md-input{
        padding: 7px;
      }
    }

  }
}
</style>
