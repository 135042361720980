<template>
  <div>
    <modal name="user-message-interactive-modal"
           :click-to-close="false"
           width="500px"
           height="auto"
           class="create-group-modal modal-custom user-message-interactive-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Cảm xúc về tin nhắn</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('user-message-interactive-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content class="post-layout">
       
      </md-card-content>
    </modal>
  </div>
</template>

<script>


export default {
  name: 'user-message-interactive-modal',
  data() {
    return {
      listEmoji : []
    }
  },
  methods: {
    beforeOpened(event) {
     this.listEmoji = event.params.listEmoji;
      // this.$store.commit("updateModalIsOpen", true);
    },

    beforeClosed(event){
      // this.$store.commit("updateModalIsOpen", false);
    },

  }

}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    max-width: 515px !important;
  }
}
.user-message-interactive-modal{
  .user-info-wrapper {
    .user-info-container {
      display: flex;
      width: 100%;
    }
    .md-avatar {
      width: 100px !important;
      height: 100px !important;
    }
    .info-text {
      width: 70%;
    }
    .info-image {
      width: 30%;
      img {
        border-radius: 50%;
      }
    }
  }

  .reaction-icon{
    width: 30px;
    height: 30px;
  }

  .follow-button {
    width: 195px;
    height: 35px;
    background-color: #EDFAD0;
    border: none;
    border-radius: 4px;
  }
}

</style>
