<template>
  <md-toolbar
      md-elevation="0"
      class="md-transparent p-0px p-0px main-header" style="z-index: 1000"
      :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <template v-if="router_name === 'Groups'">
      <div class="md-toolbar-row">
        <md-list-item>
          <img class="logo" src="/img/group/user-group.svg">
          <span class="heading-600 neutral_1 ml-15px">Nhóm</span>
        </md-list-item>
        <button class="add-group-btn mr-15px cursor-pointer" @click="openCreateGroupModal">
          <span class="d-flex">
            <md-icon>add_circle_outline</md-icon>
            <span class="text-400-default mr-15px">Tạo nhóm</span>
          </span>
        </button>
      </div>
      <ul class="nav nav-tabs tab-custom pl-5px">
        <li class="cursor-pointer" :class="{'active':$route.params.group_type == null}">
          <router-link class="text-400-medium" :to="getGroupTabLink(null)">Tổng quan</router-link>
        </li>
        <li class="cursor-pointer" :class="{'active':$route.params.group_type == 'company'}">
          <router-link class="text-400-medium" :to="getGroupTabLink('company')">Tổ chức</router-link>
        </li>
        <li class="cursor-pointer" :class="{'active':$route.params.group_type == 'work'}">
          <router-link class="text-400-medium" :to="getGroupTabLink('work')">Nhóm làm việc</router-link>
        </li>
        <li class="cursor-pointer" :class="{'active':$route.params.group_type == 'joined'}">
          <router-link class="text-400-medium" :to="getGroupTabLink('joined')">Nhóm đang tham gia</router-link>
        </li>
      </ul>
    </template>
    <div class="md-toolbar-row" v-else-if="router_name !== 'Group' && router_name !== 'Groups'">
      <template v-if="router_name === 'category' || router_name === 'sud_category' || router_name === 'wiki_page'">
        <md-list-item class="is_category">
          <md-avatar class="logo-toolbar" :style="{'background-color': category.color ? category.color : '#EDEFF5' }">
            <span class="material-icons">{{ category.icon ? category.icon : 'star' }}</span>
          </md-avatar>
          <div class="md-list-item-text">
            <span class="heading-600 neutral_1">{{ category.title }}</span>
          </div>
        </md-list-item>
      </template>

      <template v-else-if="router_name == 'messenger'">
        <md-list-item>
          <md-avatar class="logo-toolbar messenger">
            <img style="width: 100%; object-fit: cover;" class="logo" @click="openRightMenu('custom')"
                 :src="chatGroupSelected.avatar || '/img/group/group_image_low.jpg'">
          </md-avatar>
          <div class="md-list-item-text group-display-name">
            <div class="group-name-container">
                <span class="heading-500 neutral_1 chat-group-name" style="font-weight: 600 !important;" @click="openRightMenu('custom')">
                  {{ chatGroupSelected.name || '' }}
                </span>
              <div class="group-name" v-if="chatGroupSelected.type === 'BOT'">
                <div class="bot-group">
                  <span class="verified d-flex"><svg width="16px" height="16px" fill="var(--spectrum-global-color-informativePrimary)" xmlns="http://www.w3.org/2000/svg" class="gapo-Icon" role="img" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Zm4.724-13.31a1 1 0 1 0-1.448-1.38l-4.951 5.199-1.566-1.827a1 1 0 0 0-1.518 1.302l2.285 2.667a1 1 0 0 0 1.484.039l5.714-6Z" fill="#18202A"></path></svg></span>
                  <div style="display: inline-flex; align-items: center;"><span class="bot-name">Bot</span></div>
                </div>
              </div>
              </div>
            <div class="member_count" v-if="chatGroupSelected.type === 'GROUP'">
              <md-icon class="neutral_3" style="font-size: 18px !important;">person</md-icon>
              <span class="text-300-medium neutral_2"  @click="openRightMenu('member')">{{ chatGroupSelected ? chatGroupSelected.member_count + ' thành viên' : '' }}</span>
            </div>
            <div class="display-name" v-else-if="chatGroupSelected.type === 'USER'">
              <span class="text-300-medium"><span style="font-weight: 600">[{{ chatGroupSelected.user_id}}] </span>{{ chatGroupSelected.display_name || '' }}</span>
            </div>
          </div>
        </md-list-item>
      </template>

      <template v-else-if="router_name === 'department_members' || router_name === 'position_members'">
        <div class="md-list-item-text pl-20px">
          <span class="heading-600 mb-5px">Sơ đồ tổ chức</span>
<!--          <p class="text-400-default">14 thành viên</p>-->
        </div>
        <div>
        </div>
      </template>

      <template v-else-if="router_name === 'Admin setting groups'">
        <md-list-item>
          <img class="logo" src="/img/group/user-group.svg">
          <div class="md-list-item-text ml-15px">
            <span class="heading-600 neutral_1 mb-2px">Quản lý nhóm</span>
          </div>
        </md-list-item>

      </template>

      <template v-else-if="router_name === 'Admin setting chat bot'">
        <md-list-item>
          <img class="logo" src="/img/group/user-group.svg">
          <div class="md-list-item-text ml-15px">
            <span class="heading-600 neutral_1 mb-2px">Quản lý Chat Bot</span>
          </div>
        </md-list-item>

      </template>

      <template v-else-if="router_name === 'Quản lý Stickers'">
        <md-list-item>
          <img class="logo" src="/img/group/user-group.svg">
          <div class="md-list-item-text ml-15px">
            <span class="heading-600 neutral_1 mb-2px">Quản lý Chat Bot</span>
          </div>
        </md-list-item>

      </template>

      <template v-else>
        <md-list-item>
          <md-avatar class="logo-toolbar">
            <img class="logo" src="/img/toolbar/logo_toolbar.svg">
          </md-avatar>
          <div class="md-list-item-text">
            <span class="heading-600 neutral_1 mb-2px">Bảng tin</span>
            <p>Xin chào, <span class="text-400-medium">{{ user.fullname }}</span></p>
          </div>
        </md-list-item>

      </template>

      <div class="md-toolbar-section-end" v-if="router_name !== 'department_members' && router_name !== 'position_members'">
<!--        <a style="color:white;;" href="https://azagroup.asia/">-->
<!--          <md-button  class="pt-15px" style="background-color: #9E72E4 !important;padding: 15px !important; display: flex">Trang làm việc-->
<!--          </md-button>-->
<!--        </a>-->
        <md-button
            class="md-just-icon md-round md-simple md-toolbar-toggle"
            :class="{ toggled: $sidebar.showSidebar }"
            @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>
      </div>
    </div>

    <!--      <div class="md-toolbar-row" v-else>-->
    <!--        <img class="logo" src="/img/toolbar/logo_toolbar.svg" style="height: 232px; width: 100%">-->
    <!--      </div>-->

  </md-toolbar>
</template>

<script>
export default {
  data() {
    return {
      user: this.$store.state.auth.user,
      router_name: this.$route.name,
      category: this.$store.state.category.category,
      chatGroupSelected: this.$store.state.chat.chatGroupSelected,
    };
  },
  watch: {
    '$route'(to, from) {
      this.router_name = to.name
    },
    '$store.state.category.category': function (newVal, oldVal) {
      this.category = newVal
    },
    '$store.state.chat.chatGroupSelected': function (newVal, oldVal) {
      this.chatGroupSelected = newVal
    },
  },
  mounted() {
    this.router_name = this.$route.name;
  },
  created() {
    this.router_name = this.$route.name;
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    goToNotifications() {
      this.$router.push({name: "Notifications"});
    },
    goToUsers() {
      this.$router.push({name: "User Profile"});
    },
    getGroupTabLink(tab) {
      if (tab == null) {
        return "/groups";
      }
      return "/groups/" + tab;
    },
    openCreateGroupModal() {
      this.$store.commit("updateModalIsOpen", true);
      this.$modal.show('create-group-modal');
    },
    openRightMenu(name){
      let item = {...this.$store.state.chat.chatGroupSelected, open_right_menu: name};
      this.$store.commit("chat/updateChatGroupSelected", item);
    }
  },
};
</script>
<style lang="scss">
.md-toolbar {
  height: 75px;
  padding-left: 2px;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
}

.logo-toolbar {
  width: 80px;
  height: 50px;
  left: 0px;
  top: 0px;
  background: #EDEFF5;
  border: 1px solid #d8dae5;
  border-radius: 4px;
}

.messenger.logo-toolbar {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  border: 1px solid #d1d1d1;
  img {
    object-fit: cover;
  }
}

.logo-toolbar .logo {
  width: 75%;
}

.is_category {
  .material-icons {
    color: white !important;
  }
}
.member_count{
  display: flex;
  align-items: center;
  .md-icon {
    justify-content: flex-start;
    width: max-content;
    font-size: 20px !important;
  }
}
.display-name {
  display: flex;
  color: #0051a2 !important;
  align-items: center;
  margin-top: 4px;
  span {
    text-transform: uppercase;
  }
}
.md-toolbar.main-header {
  position: sticky;
  top: -1px;
}
.group-name-container{
  display: flex;
  align-items: center;
  grid-gap: 5px;
}
.bot-group {
    width: 60px;
    display: flex;
    align-items: center;
    svg{
      path{
        fill: #1a99f4;
      }
    }
    .bot-name{
      border: 1px solid #1a99f4;
      border-radius: 4px;
      color: #1a99f4;
      font-size: 12px;
      font-weight: 600;
      height: 16px;
      line-height: 14px;
      padding: 0 4px;
    }
  }
  .chat-group-name{
    overflow: unset;
  }
</style>
